import hillo from 'hillo'
import GlobalSettings from '@/model/global/GlobalSettings'
import dayjs from 'dayjs'

export async function getBusinessReportInfo (time) {
  let fromTime = time[0]
  let toTime = time[1]
  if (fromTime === toTime) {
    fromTime = dayjs(fromTime).add(4, 'hour').format('YYYY-MM-DD HH:mm:ss')
    toTime = dayjs(toTime).add(1, 'day').add(4, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')
  } else {
    fromTime = dayjs(fromTime).add(4, 'hour').format('YYYY-MM-DD HH:mm:ss')
    toTime = dayjs(toTime).add(1, 'day').add(4, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')
  }
  const res = (await hillo.post('BackendData.php?op=getBusinessReport', {
    fromDateTime: fromTime,
    toDateTime: toTime,
    lang: GlobalSettings.locale
  })).content
  res.dailyBusinessInfo
    .forEach(it => {
      it.orderInfo.dailyTurnoverRate =
        (it.orderInfo.inHouseOrderCount - res.totalTableCount) /
        res.totalTableCount * 100 > 0
          ? (it.orderInfo.inHouseOrderCount - res.totalTableCount) /
          res.totalTableCount * 100
          : 0
    })
  res.avgTurnoverRate = res.dailyBusinessInfo
    .reduce((acc, curr) => acc + curr.orderInfo.dailyTurnoverRate, 0) /
    res.dailyBusinessInfo.length || 0
  return res
}

export function getTimeByHourMinSec (t) {
  const h = parseInt(t / 60 / 60 % 24)
  const m = parseInt(t / 60 % 60)
  const s = parseInt(t % 60)
  return h + ' ' + h === 1 ? 'Hour' : 'Hours' + m + ' ' + m === 1 ? 'Minute' : 'Minutes' + s + ' ' + s === 1 ? 'Second' : 'Seconds'
}

export function dateRangeToNow (timespan) {
  const startDate = timespan[0]
  const endDate = timespan[1]
  const start = startDate.split('-')
  const end = endDate.split('-')
  const startYear = parseInt(start[0])
  const endYear = parseInt(end[0])
  const dates = []

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1
      const displayMonth = month < 10 ? '0' + month : month
      dates.push([i, displayMonth].join('-'))
    }
  }
  return dates.sort((a, b) => {
    return Date.parse(b) - Date.parse(a)
  })
}

export function setHouseRatesColumn (timespan, week) {
  // 获取时间段内的所有日期/指定星期几的日期
  week = week === 7 ? 0 : week
  let startTime = timespan[0]
  let endTime = timespan[1]
  const dateList = []
  startTime = new Date(startTime)
  endTime = new Date(endTime)
  while ((endTime.getTime() - startTime.getTime()) >= 0) {
    const year = startTime.getFullYear()
    const month = startTime.getMonth() + 1 < 10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1
    const day = startTime.getDate().toString().length === 1 ? '0' + startTime.getDate() : startTime.getDate()
    if (isNaN(week) || startTime.getDay() === week) {
      dateList.push(`${year}-${month}-${day}`)
    }
    startTime.setDate(startTime.getDate() + 1)
  }
  return dateList
}
